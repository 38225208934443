<template>
  <div class="news">
    <beacon-banner
      :bannerSrc="banner_1"
      title="时刻把握食品动态 为你我安全保驾护航"
      subTitle="news information"
    ></beacon-banner>
    <div class="tabs title">
      <router-link
        class="company_news"
        :class="{ active: $route.query.currentTab === 'comp' }"
        :to="{query: {...$route.query, currentTab: 'comp', currentPage: 1}}"
      >
        公司新闻
      </router-link>
      <span class="v_divider"></span>

      <router-link
        class="industry_news"
        :class="{ active: $route.query.currentTab === 'news' }"
        :to="{query: {...$route.query, currentTab: 'news', currentPage: 1}}"
      >
        行业动态
      </router-link>
    </div>

    <div class="content">
      <ul class="news_list">
        <li
          class="news_item"
          v-for="(news, index) in pageList"
          :key="index"
        >
          <a :href="news.url" target="_blank">
            <div class="img_wrap">
              <img class="news_img" :src="news.img" alt="" />
            </div>
            <div class="text_content">
              <time class="time">{{ news.time }}</time>
              <span class="desc">{{ news.desc }}</span>
            </div>
          </a>
        </li>
      </ul>
      <beacon-pager :total="total" :pageSize="pageSize || 6" :currentPage="Number($route.query.currentPage) || 1"></beacon-pager>
    </div>
  </div>
</template>

<script>
import { banner_1 } from "@/utils/images";
export default {
  data() {
    return {
      total: 1, // 记录数
      banner_1,
      newsList: [
        {
          type: "news",
          url: "https://mp.weixin.qq.com/s/EwuGoAi0UKIdQqxyC_EyLg",
          img: require("@/assets/images/news/20200725.png"),
          desc:
            "市场监管总局：5批次食品不合格，涉及微生物污染、 农兽药残留超标等问题",
          time: "2020-07-25",
        },
        {
          type: "news",
          url: "https://mp.weixin.qq.com/s/t1mN_ZxwQlOsGh0ZX4Q2iw",
          img: require("@/assets/images/news/20200617.png"),
          desc: "6月第2周全国最受关注的食品安全新闻（中国食安舆情排行榜）",
          time: "2020-06-17",
        },
        {
          type: "news",
          url: "https://mp.weixin.qq.com/s/jU7NZ-hLpTMNvE6kDdMbNg",
          img: require("@/assets/images/news/20200611.png"),
          desc: "江苏一小学发生食物中毒事件，“4名学生留院观察”",
          time: "2020-06-11",
        },
        {
          type: "news",
          url: "https://mp.weixin.qq.com/s/1ywcV4TkuNxJc53fC6igjA",
          img: require("@/assets/images/news/20200602.png"),
          desc: "食品交叉污染来源及控制",
          time: "2020-06-02",
        },
        {
          type: "comp",
          url: "https://mp.weixin.qq.com/s/d3IfIm-sDiqvccX1MbCJzQ",
          img: require("@/assets/images/news/20201127.webp"),
          desc: "《教育系统“制止餐饮浪费培养节约习惯”行动方案》",
          time: "2020-11-27",
        },
        {
          type: "comp",
          url: "https://mp.weixin.qq.com/s/z6PdE107kVaR9ldbtWpuTQ",
          img: require("@/assets/images/news/20201123.png"),
          desc: "市场总局等四部委联合发文，2020年校园食品安全守护行动方案！",
          time: "2020-11-23",
        },
        {
          type: "comp",
          url: "https://mp.weixin.qq.com/s/6K6b0SB6gD6sqyURDjRoYg",
          img: require("@/assets/images/news/20201118.webp"),
          desc: "2020中国教育后勤展览会 完美收官！",
          time: "2020-11-18",
        },
        {
          type: "comp",
          url: "https://mp.weixin.qq.com/s/BV5OmuesjoS18r9Lo8PDYQ",
          img: require("@/assets/images/news/20201104.png"),
          desc: "2020高校招标采购首期研讨班 在湖南长沙成功举办",
          time: "2020-11-04",
        },
        {
          type: "comp",
          url: "https://mp.weixin.qq.com/s/UsyPhFvT9Yp4Fm9WH5LWuQ",
          img: require("@/assets/images/news/20201103.png"),
          desc: "高校食品安全守护者 碧耕天食安",
          time: "2020-11-03",
        },
        {
          type: "comp",
          url: "https://mp.weixin.qq.com/s/RU9Lwaf-RlUblXceIqjRXA",
          img: require("@/assets/images/news/20201015.jpg"),
          desc: "热烈庆祝合肥金菜园餐饮管理有限公司正式上线碧耕天食安​",
          time: "2020-10-15",
        },
        {
          type: "comp",
          url: "https://mp.weixin.qq.com/s/GDdTHpQ1-Zr-kJoq8mHHfQ",
          img: require("@/assets/images/news/20200531.png"),
          desc: "碧耕“天食安”食品安全沙龙研讨会成功举办！",
          time: "2020-05-31",
        },
        {
          type: "comp",
          url: "https://mp.weixin.qq.com/s/GeOM1cTG_QT2MNtA66ZbUw",
          img: require("@/assets/images/news/20200414.png"),
          desc: "“碧耕天食安”食品安全管理平台上线签约发布启动仪式隆重举行",
          time: "2020-04-14",
        },
        {
          type: "comp",
          url: "https://mp.weixin.qq.com/s/2d2xNZx9F3XufCLHRRyfjw",
          img: require("@/assets/images/news/20200401.png"),
          desc: "浙江客户一行莅临公司参观考察",
          time: "2020-04-01",
        },
      ],
    };
  },
  computed: {
    pageSize() {
      return this.$store.state.screenWidth <= 992 ? 6 : 9;
    },
    // 计算分页数据
    pageList() {
      // 得到某个类别的所有新闻
      // debugger
      const list = this.newsList.filter(n => n.type === (this.$route.query.currentTab || "comp"));
      // 处理分页
      this.total = list.length;
      return list.slice(((this.$route.query.currentPage || 1) - 1) * this.pageSize, (this.$route.query.currentPage || 1) * this.pageSize);
    }
  },
  mounted() {
    //计算内容的高度 来判断是多少行
    var wareName = document.querySelectorAll(".news_item .desc");
    // 得到行高
    if(wareName[0]) {
      var lineH = parseInt(window.getComputedStyle(wareName[0]).lineHeight);
    }
    
    wareName.forEach((v, k) => {
      getHeight(v);
    });

    function getHeight(wareName) {
      var wareNameText = wareName.innerHTML; //获取内容
      var heightSome = wareName.clientHeight; //获取内容当前的高度
      // 56
      if (heightSome > lineH * 2) {
        //这个56数字是两行的时候的高度，根据你设定的字体大小有关
        for (var i = 0; heightSome > lineH * 2; i++) {
          //每次删掉最后一个然后返回
          wareNameText = wareNameText.substring(0, wareNameText.length - 1);
          //重新返回的字符在写在span里面 ，计算新的高度
          wareName.innerHTML = wareNameText;
          heightSome = wareName.clientHeight;
        }
        //得到正好符合高度的字符，删除最后一个变为省略号 填充在页面里
        var newText =
          wareNameText.substring(0, wareNameText.length - 1) + "...";
        wareName.innerHTML = newText;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.tabs {
  display: flex;
  font-size: 1.5rem;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #999999;
  justify-content: center;
  padding: 5.2% 0;
  > a {
    cursor: pointer;
    &.active {
      color: #333333;
    }
  }
}

.v_divider {
  width: 2px;
  background-color: #e1e1e1;
  margin: 0 2.6%;
}

.news_list {
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  .news_item {
    width: 30%;
    margin-left: 5%;
    margin-top: 5.8%;
    .img_wrap {
      height: 15rem;
      max-height: 285px;
    }
    &:nth-child(3n + 1) {
      margin-left: 0;
    }
    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3) {
      margin-top: 0;
    }
  }
  .img_wrap {
    overflow: hidden;
  }
  .news_img {
    max-width: 100%;
    transition: 0.5s;
    &:hover {
      transform: scale(1.1);
    }
  }
  .text_content {
    padding: 3.7% 7.4% 5.6% 5.6%;
    border: 1px solid #e1e1e1;
    time {
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #999999;
      display: block;
      margin-top: 4.3%;
    }
    .desc {
      margin-top: 4.3%;
      display: block;
      font-size: 19px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      line-height: 28px;
      min-height: 56px;
      color: #333333;
    }
  }
}

@media screen and (max-width: 992px) {
  .tabs {
    &.title {
      padding: 30vpx 0;
      font-weight: 400;
      font-size: 16vpx;
      color: #999999;
      &::before {
        display: none;
      }
    }
    > div {
      cursor: pointer;
      &.active {
        font-weight: 500;
        color: #333333;
      }
    }
  }

  .v_divider {
    width: 1px;
    margin: 0 15vpx;
  }

  .news_list {
    margin-top: -10vpx;
    .news_item {
      margin: 0;
      margin-top: 10vpx !important;
      width: calc(50% - 5vpx);
      &:nth-child(2n + 1) {
        margin-right: 5vpx;
      }
      &:nth-child(2n) {
        margin-left: 5vpx;
      }
      .img_wrap {
        overflow: hidden;
        height: 108vpx;
      }
    }
    .news_img {
      height: 108vpx;
    }
    .text_content {
      height: 76vpx;
      padding: 10vpx;
      time {
        margin-top: 0;
        font-size: 10vpx;
      }
      .desc {
        margin-top: 10vpx;
        min-height: 0;
        font-size: 12vpx;
        line-height: 16vpx;
      }
    }
  }
}
</style>